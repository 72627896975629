<template>
  <div class="my-3">
    <v-row class="px-3">
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          dense
          outlined
        ></v-text-field>
      </div>
    </v-row>
    <devices-table
      ref="devicesTable"
      :search="search"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import DevicesTable from './components/devices/DevicesTable.vue'

export default {
  components: {
    DevicesTable,
  },
  setup() {
    const role = localStorage.getItem('userAbility')
    const search = ref('')
    const showModal = ref(false)

    return {
      icons: {
        mdiMagnify,
      },
      role,
      search,
      showModal,
    }
  },
}
</script>
